var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6f7c54b174945d2bb3d7fe7814cd91c064bbcb65"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

// eslint-disable-next-line import/named
import { init } from '@sentry/nextjs'
import { Dedupe } from '@sentry/integrations'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

const ignoredKeywords = [
  'DOM Exception 12',
  'QuotaExceededError',
  'Maximum update depth exceeded',
  'CSSStyleSheet',
  'is not a valid discount code',
  'has already been applied',
  'timeout',
  'IndexSizeError',
  'Request aborted',
  'Network Error',
  'Load failed',
  'Cancelled'
]

const sentryOptions = {
  attachStacktrace: true,
  environment: process.env.ENVIRONMENT,
  ignoreErrors: ignoredKeywords,
  integrations: [new Dedupe()],
  maxBreadcrumbs: 50,
  allowUrls: [/https?:\/\/(account|staging.account).allplants\.com/],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1
}

if (process.env.ENVIRONMENT !== 'development') {
  init({
    dsn:
      SENTRY_DSN ||
      'https://65bbebd0006a494095c28facf851fb9c@o189517.ingest.sentry.io/6230746',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.01,
    ...sentryOptions
  })
}
